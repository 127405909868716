import { IDevice } from '../model/dashboard';

export const BIOMASS_MSG_ID = 25;
export const DRYAIR_MSG_ID = 40;

export class DeviceUtils {
  static getDeviceName(device: Partial<IDevice>) {
    return device.name ?? `Camera ${device.thermal_camera_id}`;
  }

  static applyCamerasFilter(devices: IDevice[]) {
    return devices.filter((d) => d.thermal_camera_id);
  }

  static applySensorsFilter(devices: IDevice[]) {
    return devices.filter((d) => !d.thermal_camera_id);
  }
}
