import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import {
  FileParameter,
  INodesData,
  IVisualMapGetResponseItem,
} from '../../../../api/api-sdk';
import {
  INodeForm,
  INodeListItem,
  IVariableAlert,
} from '../../../../model/node';
import { UiMode } from './nodes.feature';

export const NodesActions = createActionGroup({
  source: 'Nodes',
  events: {
    toggleAttribute: props<{ id: string }>(),
    nodesDataUpdated: props<{ nodesData: INodesData[] }>(),
    nodesAndAlertsUpdated: props<{
      nodes: INodeListItem[];
      alerts: IVariableAlert[];
    }>(),
    readingColumnsUpdated: props<{ columns: ColDef[] }>(),
    startActiveNodeScrolling: props<{ nodeId: number }>(),
    scrollActiveNode: props<{ delta: number }>(),
    nodeUpdated: props<{ nodeId: number; form: INodeForm }>(),
    updateNodesData: emptyProps(),
    setNodesSortField: props<{
      sortField: { field: string; direction?: 'asc' | 'desc' };
    }>(),
    customNodesOrderFetched: props<{ nodesOrder: number[] }>(),
    customNodesOrderUpdated: props<{ nodesOrder: number[] }>(),
    openChartsForNode: props<{ node: INodeListItem }>(),
    createVisualMap: props<{ name: string }>(),
    updateVisualMap: props<
      Partial<{
        id: number;
        name: string;
        data: string;
        image: FileParameter;
      }>
    >(),
    visualMapUpdated: props<{ visualMap: IVisualMapGetResponseItem }>(),
    visualMapsFetched: props<{ visualMaps: IVisualMapGetResponseItem[] }>(),
    openVisualMap: props<{ visualMap: IVisualMapGetResponseItem }>(),
    selectVisualMapDevice: props<{ deviceId: number }>(),
    deleteVisualMap: props<{ id: number }>(),
    setUiMode: props<{ uiMode: UiMode }>(),
  },
});
